import ApiService from "@/services/api_service"
import {IApiResponse, IData} from "@/types"
import qs from 'qs';

const AutomationService = {

  getAutomationLogs(offset: number, project: string, cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'get_aut_logs'

      const params: IData = {
        action: actionName,
        offset,
        flow: project,
      }

      ApiService.request({
        method: "get",
        params,
      }, cancelTokenId, options).then((data) => {
        console.log('AutomationService getAutomationLogs success', data)
        resolve(data)
      }).catch((data) => {
        console.log('AutomationService getAutomationLogs error', data)
        reject(data)
      })
    })
  },

  getRuleTemplate(templateId: number, projectId: string, cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'new_aut_rule'

      const params: IData = {
        action: actionName,
        rule_template: templateId,
        project_id: projectId,
      }

      ApiService.request({
        method: "get",
        params,
      }, cancelTokenId, options).then((data) => {
        console.log('AutomationService getRuleTemplate success', data)
        resolve(data)
      }).catch((data) => {
        console.log('AutomationService getRuleTemplate error', data)
        reject(data)
      })
    })
  },

  getNomapProjectFields(fromProjectId: string, toProjectId: string, cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'get_nomap_project_fields'

      const params: IData = {
        action: actionName,
        from_project_id: fromProjectId,
        to_project_id: toProjectId,
      }

      ApiService.request({
        method: "get",
        params,
      }, cancelTokenId, options).then((data) => {
        console.log('AutomationService getNomapProjectFields success', data)
        resolve(data)
      }).catch((data) => {
        console.log('AutomationService getNomapProjectFields error', data)
        reject(data)
      })
    })
  },

  saveRule(rule: IData, cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'save_aut_rule'

      const params: IData = {
        action: actionName,
        rule,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId, options).then((data) => {
        console.log('AutomationService saveRule success', data)
        resolve(data)
      }).catch((data) => {
        console.log('AutomationService saveRule error', data)
        reject(data)
      })
    })
  },

  removeRule(ruleId: string, cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'remove_aut_rule'

      const params: IData = {
        action: actionName,
        rule: {
          id: ruleId,
        }
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId, options).then((data) => {
        console.log('AutomationService removeRule success', data)
        resolve(data)
      }).catch((data) => {
        console.log('AutomationService removeRule error', data)
        reject(data)
      })
    })
  },

  moveRule(ruleId: string, prevRuleId = '', projectId: string, cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'move_aut_rule'

      const params: IData = {
        action: actionName,
        rule: {
          rule_id: ruleId,
          project_id: projectId,
          prev_rule: prevRuleId,
        }
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId, options).then((data) => {
        console.log('AutomationService moveRule success', data)
        resolve(data)
      }).catch((data) => {
        console.log('AutomationService moveRule error', data)
        reject(data)
      })
    })
  },

  toggleRulePause(ruleId: string, pause: '0' | '1', cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'pause_aut_rule'

      const params: IData = {
        action: actionName,
        rule: {
          id: ruleId,
          pause,
        }
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId, options).then((data) => {
        console.log('AutomationService toggleRulePause success', data)
        resolve(data)
      }).catch((data) => {
        console.log('AutomationService toggleRulePause error', data)
        reject(data)
      })
    })
  },

}

export default AutomationService